import React from 'react'
import styled from 'styled-components';
import { Box, Container, Image } from './Components';
import LogoImg from "../images/logo.png";

const Logo = styled.div`
    width: 180px;
    padding: 16px;
    margin: auto;
`;

export const TopNav: React.FC = () => {
    return (
        <Box>
            <Container>
                <Logo>
                    <Image src={LogoImg} />
                </Logo>
            </Container>
        </Box>
    );
}