import styled, { css } from 'styled-components';
import { media } from "../media";

interface BoxProps {
    width?: string;
    height?: string;
};

export const Container = styled.div`
    width: 90%;
    margin: auto;
    ${media.up("lg")} {
        width: 900px;
    } 
`;

export const Image = styled.img`
    display: block;
    max-width: 100%;
`;

export const Box = styled.div<BoxProps>`
    ${css`
        width: ${(props: BoxProps) => props.width ? `${props.width}` : "auto"};
        height: ${(props: BoxProps) => props.height ? `${props.height}` : "auto"};
    `}
`;

export const Input = styled.input`

`;

export const Label = styled.label`

`;

export const FormGroup = styled.div`

`;

export const ValidationError = styled.p`
    color: "#dd3131"
`;

