import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

interface FooterProps {}

const FooterContainer = styled.div`
  color: #aaa !important;
  width: 100%;
  padding: 40px;
  border-top: 1px solid #ccc;
  background: #649767;
`;

const Social = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;
`;

const SocialLink = styled.a``;

const SocialText = styled.p`
  font-size: 24px;
  font-weight: lighter;
  text-align: center;
  color: #fff;
`;

const SubFooter = styled.div`
  padding-top: 25px;
  background-color: #649767;
  color: #fff;
  font-size: 13px;
  width: 100%;
  text-align: center;
  padding-bottom: 25px;
`;

const PrivacyLink = styled(Link)`
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  display: block;
  color: #ccc;
  margin-top: 32px;
  &:hover {
    text-decoration: underline;
  }
`;

export const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <>
      <FooterContainer>
        <Social>
          <SocialLink
            href="https://instagram.com/newrecifeoficial"
            style={{ marginRight: 10 }}
          >
            <StaticImage
              src="../images/insta.svg"
              alt="Instagram"
              style={{ width: 36, height: 36 }}
            />
          </SocialLink>
          <SocialLink
            href="https://www.facebook.com/newrecife"
            style={{ marginLeft: 10 }}
          >
            <StaticImage
              src="../images/Face.svg"
              alt="Facebook"
              style={{ width: 36, height: 36 }}
            />
          </SocialLink>
        </Social>
        <SocialText>Acompanhe as novidades da New Recife</SocialText>
        <PrivacyLink to="/politica-de-privacidade">
          Politica de privacidade
        </PrivacyLink>
      </FooterContainer>
      <SubFooter>© 2022 New Recife</SubFooter>
    </>
  );
};
